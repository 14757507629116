/**
 * Created by mac on 2019-09-06
 */

var Farm = function () {
    cleverapps.EventEmitter.call(this);

    this.config = cc.loader.getRes(bundles.main.jsons.farm_data_json);

    this.probabilities = cleverapps.clone(this.config.probabilities);
    for (var i = 1; i < this.probabilities.length; i++) {
        this.probabilities[i] += this.probabilities[i - 1];
    }

    this.buildings = [];
    this.buildings = this.config.items.map(function (buildingData, index) {
        return new Building(this, buildingData, index);
    }, this);

    this.keys = {};
    this.load();

    this.addPersons();

    if (cleverapps.config.debugMode) {
        this.validateDialogues();
    }
    this.validateQuestItems();
};

Farm.prototype = Object.create(cleverapps.EventEmitter.prototype);
Farm.prototype.constructor = Farm;

Farm.prototype.createDialogue = function (dialogueName) {
    var dialogue = this.config.dialogues[dialogueName];

    if (!dialogue) {
        return undefined;
    }

    return new Dialogue(dialogue, {
        autoClose: true,
        showUp: true,
        bundles: bundles
    });
};

Farm.prototype.addPersons = function () {
    for (var i = 0; i < 100; i++) {
        var role = "person" + i;
        if (!bundles[role]) {
            return;
        }

        cleverapps.persons.add(role, {
            name: role,
            bundle: role,
            json: bundles[role].jsons[role]
        });
    }
};

Farm.prototype.validateDialogues = function () {
    Object.values(this.config.dialogues).forEach(function (dialogue) {
        dialogue.forEach(function (step) {
            var person = step.person === "string" && step.person.split("_")[0];
            if (person && !cleverapps.persons.choose(person)) {
                throw "No person: " + person;
            }
        });
    });
};

Farm.prototype.validateQuestItems = function () {
    this.config.questItems.forEach(function (item, ind) {
        if (!bundles.farm_items.frames["item" + ind]) {
            throw "No questItem resource - " + item;
        }
    });
};

Farm.prototype.gamePlayed = function (outcome, game) {
    if (cleverapps.config.adminMode) {
        return;
    }

    if (outcome === GameBase.OUTCOME_VICTORY && game.level.building) {
        game.rewards.keys = {};
        game.rewards.keys[game.level.building.levelUp()] = 1;
    }
};

Farm.prototype.setupResolution = function () {
    this.buildings.forEach(function (building) {
        building.setupResolution();
    });
};

Farm.prototype.chooseNextLevel = function () {
    var current = cleverapps.user.getCurrentLevel();

    if (this.existLevel(current)) {
        cleverapps.user.incLevel();
        current = cleverapps.user.getCurrentLevel();
    }

    return current;
};

Farm.prototype.existLevel = function (current) {
    for (var i = 0; i < this.buildings.length; i++) {
        var building = this.buildings[i];
        if (building.label && building.label.episodeNo === current.episodeNo && building.label.levelNo === current.levelNo) {
            return true;
        }
    }

    return false;
};

Farm.prototype.getMapSize = function () {
    var data = this.config;
    return {
        width: data.map.width * resolutionScale,
        height: data.map.height * resolutionScale
    };
};

Farm.prototype.findProperBuilding = function (item) {
    for (var i = 0; i < this.buildings.length; i++) {
        var building = this.buildings[i];

        if (building.provides === item.name) {
            return building;
        }
    }

    return undefined;
};

Farm.prototype.findBuildingForActiveItem = function () {
    if (this.isCompleted()) {
        return undefined;
    }

    if (this.getBuilding().getQuest().isCompleted()) {
        return undefined;
    }

    return this.findProperBuilding(this.getBuilding().getQuest().findActiveItem());
};

Farm.prototype.scrollTo = function (building, f) {
    this.trigger("scrollTo", building, false, f);
};

Farm.prototype.complete = function (f) {
    new AllDoneWindow();
    AllDoneWindow.showed = true;
    cleverapps.meta.onceNoWindowsListener = f;
};

Farm.prototype.load = function (serverData) {
    var data = serverData || Farm.LoadFromStorage();
    if (data && data.keys && data.buildings && data.building !== undefined) {
        this.keys = this.parseOldKeys(data.keys);

        for (var i = 0; i < data.buildings.length; i++) {
            if (this.buildings[i]) {
                this.buildings[i].load(data.buildings[i]);
            }
        }

        this.currentBuildingId = data.building;
        var building = this.getBuilding();
        if (building && data.quest !== undefined) {
            building.currentQuestId = data.quest;
        }

        if (data.justCollectedDialogue) {
            this.justCollectedDialogue = data.justCollectedDialogue;
        }
    } else {
        this.currentBuildingId = 0;
    }
};

Farm.prototype.parseOldKeys = function (oldKeys) {
    if (oldKeys.mentos === undefined) {
        return oldKeys;
    }

    var newKeys = {};
    var oldPerLevelAmount = 3;
    for (var key in oldKeys) {
        if (this.findProperBuilding({ name: key })) {
            newKeys[key] = Math.round(oldKeys[key] / oldPerLevelAmount);
        }
    }

    return newKeys;
};

Farm.prototype.getBuilding = function () {
    return this.buildings[this.currentBuildingId];
};

Farm.prototype.updateInfo = function (serverData) {
    this.load(serverData);
    this.save(true);
};

Farm.prototype.getInfo = function () {
    var buildings = [];
    for (var i = 0; i < this.currentBuildingId; i++) {
        buildings.push(this.buildings[i].save());
    }

    var save = {
        building: this.currentBuildingId,
        quest: this.getBuilding() && this.getBuilding().currentQuestId,
        keys: this.keys,
        buildings: buildings
    };

    if (this.justCollectedDialogue) {
        save.justCollectedDialogue = this.justCollectedDialogue;
    }

    return save;
};

Farm.prototype.save = function (fromSever) {
    cleverapps.dataLoader.save(DataLoader.TYPES.FARM, this.getInfo());
    if (!fromSever) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
};

if (cleverapps.config.debugMode) {
    Farm.prototype.reset = function () {
        this.keys = {};
        this.currentBuildingId = 0;
        this.buildings[0].currentQuestId = 0;
        delete this.justCollectedDialogue;

        this.save();
    };
}

Farm.prototype.listAvailableProvidesBuildings = function () {
    var list = [];
    for (var i = 0; i < this.buildings.length && i < this.currentBuildingId; i++) {
        if (this.buildings[i].provides) {
            list.push(this.buildings[i]);
        }
    }

    if (!list.length && this.currentBuildingId === 0) {
        list.push(this.buildings[0]);
    }

    return list;
};

Farm.prototype.onEnter = function () {
    var building = this.getBuilding();

    if (building && building.isComplete()) {
        building.open = true;
        this.currentBuildingId++;
        this.save();
    }
};

Farm.prototype.moveNext = function (f) {
    var building = this.getBuilding();

    if (this.isCompleted()) {
        f();
        return;
    }

    var items = this.getBuilding().getQuest().items;
    items.forEach(function (key) {
        this.keys[key.name] -= key.amount;
    }, this);

    if (this.getBuilding().nextQuest()) {
        this.trigger("change:stage", f, true);
    } else {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_ITEM + "_" + this.currentBuildingId);
        this.currentBuildingId++;
        var options = {
            finished: building,
            newbie: this.getBuilding()
        };
        this.trigger("change:building", options, f);
    }

    this.save();
};

Farm.prototype.checkForItemDialogue = function (provided) {
    if (this.isCompleted()) {
        return;
    }

    var curQuest = this.getBuilding().getQuest();
    if (curQuest.isCompleted()) {
        return;
    }

    if (cleverapps.config.name === "heroes" && !cleverapps.config.debugMode) {
        return;
    }

    curQuest.getStatus().forEach(function (item) {
        if (item.name === provided) {
            if (item.status < item.amount && (item.status + 1) === item.amount) {
                if (curQuest.itemsDialogue && curQuest.itemsDialogue[item.name]) {
                    this.justCollectedDialogue = curQuest.itemsDialogue[item.name];
                }
            }
        }
    }, this);
};

Farm.prototype.earnKeys = function (key) {
    this.checkForItemDialogue(key);

    this.keys[key] = (this.keys[key] || 0) + 1;

    this.save();
};

Farm.prototype.isCompleted = function () {
    return this.getBuilding() === undefined;
};

Farm.prototype.getLatestBuilding = function () {
    if (this.getBuilding()) {
        return this.getBuilding();
    }

    return this.buildings[this.buildings.length - 1];
};

Farm.prototype.getQuestPrize = function (future) {
    var seed = this.currentBuildingId + this.getBuilding().currentQuestId + (future ? 1 : 0);
    return {
        exp: cleverapps.Random.choose([2, 3, 5], seed),
        unlimitedLives: "30 minutes"
    };
};

Farm.prototype.processChatMessage = function (message) {
    if (!message.metha.farm) {
        return;
    }

    Farm.Reset();
    var newData = Farm.GetBuildingProgress(message.metha.farm.building, message.metha.farm.quest);
    this.load(newData);
    this.save();
};

Farm.LoadFromStorage = function () {
    return cleverapps.dataLoader.load(DataLoader.TYPES.FARM);
};

Farm.Reset = function () {
    var farm = cleverapps.farm;

    farm.buildings.forEach(function (building) {
        building.label = undefined;
        building.level = 0;
        building.currentQuestId = 0;
        building.open = false;

        building.quests.forEach(function (quest) {
            quest.solved = false;
        });
    });

    farm.keys = {};
    farm.currentBuildingId = 0;
};

Farm.GetBuildingProgress = function (targetBuildingId, questNo) {
    var fakeKeys = {};
    var res = {
        keys: {},
        building: targetBuildingId,
        quest: questNo || 0,
        buildings: []
    };

    var farm = cleverapps.farm;
    farm.buildings.forEach(function (building) {
        if (building.id > targetBuildingId) {
            return;
        }

        res.buildings[building.id] === undefined && (res.buildings[building.id] = { level: 0 });

        building.quests.forEach(function (quest, ind) {
            if (targetBuildingId === building.id && ind >= res.quest) {
                return;
            }

            quest.items.forEach(function (item) {
                if (fakeKeys[item.name] === undefined) {
                    fakeKeys[item.name] = 0;
                }

                while (fakeKeys[item.name] < item.amount) {
                    var sourceBuilding = farm.findProperBuilding(item);
                    res.buildings[sourceBuilding.id] === undefined && (res.buildings[sourceBuilding.id] = { level: 0 });
                    res.buildings[sourceBuilding.id].level++;

                    var itemName = sourceBuilding.provides;
                    fakeKeys[itemName] === undefined ? fakeKeys[itemName] = 1 : fakeKeys[itemName]++;
                }

                fakeKeys[item.name] -= item.amount;
            });
        });
    });

    return res;
};