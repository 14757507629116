/**
 * Created by Reda on 11.10.2024
 */
RewardTypes.stars = {
    getIcon: function () {
        return bundles.reward_icons.frames.stars_png;
    },

    getSmallIcon: function () {
        return bundles.reward_icons.frames.stars_small_png;
    },

    handler: function (value) {
        cleverapps.starChest.addStars(value, true);
        return function () {
            cleverapps.sideBar.resetByClassName(StarChestIcon);
            cleverapps.starChest.onChangeStars();
        };
    }
};